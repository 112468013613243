/*---------------------------------*\
\*---------------------------------*/
/*---------------------------------*\
    
Project: Haffhus Energy
Author: Maurice Wegner
    
© 2019 – All rights reserved.

\*---------------------------------*/
/*---------------------------------*\
\*---------------------------------*/

/*------------------------------------*\
    MAIN
\*------------------------------------*/

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  text-rendering: optimizeLegibility;
}

body {
  font-family: $ubuntu;
  font-weight: 400;
  font-size: 100%;
  color: var(--text-color);
  background: var(--bg-color);
  background-repeat: no-repeat;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--main-color);
}

.bg {
  background: var(--bg-color);
  background-repeat: no-repeat;
}

/*------------------------------------*\
    HEADINGS
\*------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $montserrat;
  font-weight: 600;
  letter-spacing: -1px;
}

/*------------------------------------*\
    NAVBAR
\*------------------------------------*/

#menuecontent {
  position: fixed;
  left: 0;
  top: 0;
  width: $navWidth;
  bottom: 0;
  max-width: 72%;
  background: var(--navbar-bg-color);
  box-shadow: var(--lt-shadow);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  transform: translate3d(0, 0, 0);
  transition: transform ease 0.5s;
  z-index: 12;

  @media #{$phone} {
    transform: translate3d(-100%, 0, 0);

    &.active {
      transform: translate3d(0, 0, 0);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & > ul {
    margin: 2rem 0;
  }

  ul {
    list-style: none;

    li.main {
      margin-bottom: 0.5rem;
    }

    li {
      cursor: pointer;
      position: relative;
      overflow: hidden;
      opacity: 0.65;
      transition: opacity 0.3s;
      background-color: var(--navbar-elem-color);
      transition: background-color 0.25s;

      &.sub::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0.95rem;
        padding: 0.75rem 1.5rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDQ2LjAyIDQ2LjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0Ni4wMiA0Ni4wMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTE0Ljc1Nyw0Ni4wMmMtMS40MTIsMC0yLjgyNS0wLjUyMS0zLjkyOS0xLjU2OWMtMi4yODItMi4xNy0yLjM3My01Ljc4LTAuMjA0LTguMDYzbDEyLjc1OC0xMy40MThMMTAuNjM3LDkuNjQ1ICAgIEM4LjQ2LDcuMzcsOC41NCwzLjc2LDEwLjgxNiwxLjU4MmMyLjI3Ny0yLjE3OCw1Ljg4Ni0yLjA5Nyw4LjA2MywwLjE3OWwxNi41MDUsMTcuMjUzYzIuMTA0LDIuMiwyLjEwOCw1LjY2NSwwLjAxMyw3Ljg3MiAgICBMMTguODkzLDQ0LjI0N0MxNy43Nyw0NS40MjQsMTYuMjY3LDQ2LjAyLDE0Ljc1Nyw0Ni4wMnoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIHN0eWxlPSJmaWxsOiNCMUIxQjEiPjwvcGF0aD4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24%;
        transform: rotate(90deg);
        transition: transform 0.3s ease;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 1;
          background-color: var(--navbar-elem-active);

          & > a {
            border-left: 4px solid var(--main-color);
            padding-left: 2.3rem;
          }
        }
      }

      &.active {
        opacity: 1;
        background-color: var(--navbar-elem-active);

        & > a {
          border-left: 4px solid var(--main-color);
          padding-left: 2.3rem;
        }
      }

      a {
        display: block;
        color: var(--text-color);
        font-weight: 400;
        font-size: 1.1rem;
        padding: 1rem;
        padding-left: 2rem;
        border-left: 4px solid transparent;
        transition: padding-left 0.3s ease, border-left 0.3s;
      }

      ul {
        max-height: 0;
        pointer-events: none;

        li {
          opacity: 1;
          background-color: var(--navbar-child-elem-color);

          &:hover {
            background-color: var(--navbar-child-elem-active);
          }

          a {
            font-weight: 300;
            font-size: 1rem;
            padding: 0.85rem;
            padding-left: 2.3rem;
            opacity: 0.75;

            &:hover {
              padding-left: 2.5rem;
              opacity: 1;
            }
          }
        }
      }

      &.active {
        &.sub::after {
          transform: rotate(270deg);
        }
        ul {
          max-height: inherit;
          pointer-events: auto;
        }
      }
    }
  }
}

#overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--overlay-opacity);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

/*------------------------------------*\
    CONTENT
\*------------------------------------*/

.content {
  position: absolute;
  top: 5rem;
  left: #{$navWidth};
  width: 60%;
  width: calc(100% - #{$navWidth});
  padding: 2.75rem;
  overflow: hidden;
  transition: left 0.5s ease, width 0.5s ease;

  &.opened {
    width: calc(100% - #{$navWidth} - #{$navInnerWidth});
    left: calc(#{$navWidth} + #{$navInnerWidth});
  }

  @media #{$medium} {
    padding: 1.5rem;
  }

  @media #{$phone} {
    width: 100%;
    left: inherit;
    padding: 0;
  }
}

/*------------------------------------*\
    OL
\*------------------------------------*/

ol {
  margin: 1.5rem;

  li {
    margin: 1rem 0;
  }
}

/*------------------------------------*\
    GRID
\*------------------------------------*/

.content {
  h1 {
    margin: 2rem 1rem 1rem;
    font-size: 3.5rem;
    font-weight: 700;

    @media #{$medium} {
      font-size: 3rem;
      padding: 0 1rem;
    }

    @media #{$phone} {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 3rem;
    padding: 0 1rem;

    @media #{$medium} {
      font-size: 2.75rem;
      padding: 0 2rem;
    }

    @media #{$phone} {
      font-size: 2rem;
    }
  }

  .grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: 2rem;
    padding: 1rem;
    margin: 0 0 4rem;
    overflow-x: scroll;

    @media #{$medium} {
      margin-bottom: 3rem;
    }

    @media #{$medium} {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 1rem;
      margin-bottom: 2rem;
      scrollbar-width: none;
      gap: 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    table img {
      display: none;
    }

    .grid--elem {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: hidden;
      min-height: 17rem;
      border-radius: 10px;
      box-shadow: var(--lt-shadow);
      background: var(--grid-bg-color);
      transform: scale(1);
      transition: transform 0.35s ease;

      &:not(.loading) {
        @include fadeInScale("fadeInScale75and05", 0.75, 1, 0.5s);
      }

      @media (hover: hover) {
        &:hover {
          transform: scale(1.03);
        }
      }

      @media #{$medium} {
        min-height: 14rem;
        margin: 0 1rem;
        min-width: 15rem;
      }

      &.full {
        grid-column: 1 / 3;

        @media #{$phone} {
          max-width: 25rem;
        }
      }

      span.name {
        display: block;
        width: 100%;
        padding: 1rem;
        padding-right: 3.5rem;
      }

      .value {
        position: absolute;
        top: 50% + $value_offsetTop;
        transform: translateY(-50%);
        z-index: 1;

        span {
          display: block;
          color: var(--status-std);
          font-size: 2.5rem;
          font-family: $montserrat;
          font-weight: 700;
          transition: color 0.5s;

          &.ok {
            color: var(--status-ok);
          }

          &.moderate {
            color: var(--status-moderate);
          }

          &.critical {
            color: var(--status-critical);
          }
        }
      }

      &.full {
        .value {
          span {
            font-size: 3.75rem;
          }
        }
      }

      .unit {
        font-size: 0.8rem;
        display: block;
        position: absolute;
        bottom: 1rem;
        right: 1.25rem;
        color: var(--unit-color);
      }
    }

    &.gesamt {
      .grid--elem {
        flex: 1 0 15rem;
        max-width: 34rem;
      }
      .value {
        span {
          font-size: 3.5rem !important;
          margin-bottom: 0.5rem;
        }
      }
    }

    /*-----------------------------------*\
      LADESTATION
  \*-----------------------------------*/

    &.chargingstation {
      svg.status {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        fill: var(--battery-inactive);
        transform: scale(1);
        transition: fill 0.5s, transform 0.35s ease;

        &.active {
          fill: var(--battery-active);
        }

        @media (hover: hover) {
          &:hover {
            transform: scale(1.2);
          }
        }
      }

      .active {
        .value span {
          color: var(--status-ok);
        }

        svg.status {
          fill: var(--battery-active);
          animation: batteryblink 0.8s alternate infinite linear;
        }
      }
    }
  }
}

/*------------------------------------*\
    KEYFRAMES
\*------------------------------------*/

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@keyframes batteryblink {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shine {
  0% {
    background-position: -300px;
  }
  100% {
    background-position: 300px;
  }
}

//BERLIN

.berlin {
  #value25 {
    color: var(--status-moderate) !important;
  }
}

/*
There's currently a bug on iOS touch devices when using "-webkit-overflow-scrolling: touch" which
automatically displays the scrollbar even though the following snippet should hide it. The only fix 
for this is to manually add a wrapper with a fixed height and hide its overflow while using 
padding on its child containers. I do not want to add this fix for now since it's not a suitable 
fix concerning responsivity. This issue is said to be fixed in iOS 13.
*/
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
