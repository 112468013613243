.pools {
    .lighting {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        fill: var(--battery-inactive);

        &.activated {
            fill: var(--status-ok);
        }
    }

    .cover {
        position: absolute;
        top: 0.75rem;
        right: 1rem;
        height: 1.75rem;
        width: 1.75rem;
        fill: var(--battery-inactive);

        .roof {
            opacity: 0.25;
        }

        &.covered {
            .roof {
                opacity: 1;
                fill: var(--status-ok);
            }
        }
    }
}
